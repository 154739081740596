import { json, redirect } from "react-router-dom";
import { IAppRoutesConfigValue, appRoutes } from "../constants/appRoutes";
import { authGuard } from "../utils/authGuard";
import { asyncWrap } from "../utils/helper";
import { loginApi } from "../apis/login";
import { constants } from "../constants/constants";
import { dataStore } from "../stores/dataStore";
import { localStore } from "../stores/localStore";

export const injectAuthGuard = (parentRoute: any) => {
    Object.values(parentRoute).forEach((v: any) => {
        const config = Object.values(appRoutes).find(x => x.path === v?.path) as IAppRoutesConfigValue;
        if (config?.authConfig) {
            const existingLoader = v?.loader ?? (() => true);
            v.loader = async (...args: any) => {
                const { redirectUrl, ...authParams } = config.authConfig || {};
                const isRedirect = authGuard(authParams);
                if (isRedirect){
                    if(typeof redirectUrl === 'function') return redirect(redirectUrl());
                    return redirect(redirectUrl || appRoutes.root.path);
                }
                return existingLoader(...args);
            }
        }
        if (v?.children) {
            v?.children?.forEach((x: any) => injectAuthGuard({ route: x }))
        }
    })
}

export const handleLogin = async (url: any) => {
    const [error, response] = await asyncWrap(loginApi(url));
    // const [error, response] = await asyncWrap(
    //     loginApi(
    //         "https://dev.screener.stargazer.qburst.build" + url.slice(21)
    //     )
    // );
    const data = response?.data?.data;
    if (error || !data) throw json("", 500);
    const value = {
        token: data?.access_token,
        refreshToken: data?.refresh_token,
        logoutUrl: data?.logout_url,
        role: constants.userRoles.HR,
    };
    dataStore.auth.updateValue(value);
    localStore.auth.updateValue(value);
    return true
}