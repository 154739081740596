interface IEncode {
    encode: (value: number | string | object | Array<any> | boolean) => string
    decode: <T>(value: string) => T
}

export const encoder: IEncode = {
    encode: (value) => {
        let data = value;
        if(['object','boolean','number'].includes(typeof value) || Array.isArray(value)){
            data = JSON.stringify(value);
        } else if(typeof value !== 'string'){
            data = '';
        }
        return btoa(data as string);
    },
    decode: (value) => {
        const data = atob(value);
        try {
            return JSON.parse(data);
        } catch(_){
            return data;
        }
    }
}