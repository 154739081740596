import { LoaderFunctionArgs } from "react-router-dom";
import { appRoutes } from "../../constants/appRoutes";
import { ErrorBoundary } from "../../pages/CandidateWelcome/ErrorBoundary";

export interface ICandidateWelcomeLoader {
    params: { token: string },
    request: Request;
}

export const candidateWelcomeRoute = {
    path: appRoutes.candidateWelcome.path,
    async loader({ params, request }: LoaderFunctionArgs) {
      let { loader } = await import("../../pages/CandidateWelcome/loader");
      return loader({ params, request } as ICandidateWelcomeLoader);
    },
    lazy: () => import("../../pages/CandidateWelcome"),
    ErrorBoundary: ErrorBoundary,
}