export const createDataStore = <T,U=T>() => {
    let model: T | undefined;
    return {
        getValue: () => model,
        setValue: (data: T) => model = data,
        updateValue: (data: T) => {
            if(typeof data === 'object'){
                model = { ...model, ...data }
            } else {
                model = data;
            }
        },
        deleteValue: (key?: Extract<keyof T, keyof U> | Array<Extract<keyof T, keyof U>>) => {
            if(key && typeof model === 'object'){
                if(Array.isArray(key)){
                    const rest = Object.entries(model as object).reduce((res, [k,v]) => {
                        if(!key.some(x => x === k)) res[k] = v;
                        return res;
                    },{} as any)
                    model = rest;
                } else {
                    if((model)?.[key]){
                        const { [key]:del, ...rest } = model;
                        model = rest as any;
                    }
                }
            } else {
                model = undefined;
            }
        },
        clearStore: () => model = undefined,
    }
}