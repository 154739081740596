import axios from "axios";
import { envConstants } from "../constants/envConstants";
import { responseInterceptor } from "./axiosInterceptors";

const apiBaseConfig = {
    baseURL: envConstants.AUTH_API_URL,
    headers: {
        Accept: 'application/json',
    }
}

export const AxiosAuthApi = axios.create(apiBaseConfig);
AxiosAuthApi.interceptors.response.use(...responseInterceptor);