export interface ILsBase {
    getValue: <T>(key: string) => T | null
    setValue: (key: string, value: number | string | object | Array<any> | boolean) => void
    deleteValue: (key: string) => void
    clearStorage: () => void
}

export const lsBase: ILsBase = {
    getValue: (key) => {
        const data = localStorage.getItem(key);
        if(data){
            try {
                return JSON.parse(data);
            } catch(_){
                return data;
            }
        }
        return null;
    },
    setValue: (key, value) => {
        let data = value;
        if(['object','boolean','number'].includes(typeof value) || Array.isArray(value)){
            data = JSON.stringify(value);
        } else if(typeof value !== 'string'){
            data = '';
        }
        localStorage.setItem(key, data as string);
    },
    deleteValue: (key: string) => {
        localStorage.removeItem(key);
    },
    clearStorage: () => {
        localStorage.clear();
    }
}