import { getSupportedAudioMime, getSupportedVideoMime } from "../utils/helper";

export const constants = {
    userRoles: {
        CANDIDATE: 'CANDIDATE' as const,
        ADMIN: 'ADMIN' as const,
        HR: 'HR' as const
    },
    ...getSupportedVideoMime(),
    ...getSupportedAudioMime()
}

export const GoogleAnalyticsScreen: { [key: string]: string } = {
  "candidate-welcome": "welcome_page",
  "candidate-interview": "interview_page",
  "interview-feedback": "feedback_page"
};

export const interviewStatuses = {
  CREATED: 0,
  SHORTLISTED: 1,
  INVITED: 2,
  SCHEDULED: 3,
  RESCHEDULED: 4,
  CANCELLED: 5,
  IN_PROGRESS: 6,
  COMPLETED: 7,
  EVALUATED: 8,
  ON_HOLD: 9,
  INTERRUPTED: 10,
};

