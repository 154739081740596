import { AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { getAuthToken } from "../utils/helper";

export const requestInterceptor = async (request: InternalAxiosRequestConfig<any>) => {
    const token = getAuthToken();
    if(token) request.headers.Authorization = token;
    return request;
}

export const responseInterceptor = [
    async (response: AxiosResponse<any>) => {
        return response;
    },
    async (error: any) => {
        return Promise.reject({
            status: error?.response?.status || 500,
            data: error?.response?.data || {}
        })
    }
]