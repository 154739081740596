import { AxiosProgressEvent } from "axios";
import { constants } from "../constants/constants";
import { dataStore } from "../stores/dataStore";
import { localStore } from "../stores/localStore";

export const asyncWrap = async <T extends Promise<any>>(
  promise: T
): Promise<[any, Awaited<T> | null]> => {
  try {
    const data = await promise;
    return [null, data];
  } catch (error) {
    return [error, null];
  }
};

export const joinClass = (...classes: Array<any>) => {
  return classes.join(" ");
};

export const getSupportedVideoMime = () => {
  const mimeTypes = ["video/webm", "video/mp4"];
  const supportedMime = mimeTypes.filter((x) =>
    MediaRecorder.isTypeSupported(x)
  )?.[0];
  return {
    supportedVideoType: supportedMime,
    supportedVideoExtension: supportedMime
      ? `${supportedMime.substring(supportedMime.lastIndexOf("/") + 1)}`
      : "",
  };
};

export const getSupportedAudioMime = () => {
  const mimeTypes = ["audio/webm", "audio/mp4"];
  const supportedMime = mimeTypes.filter((x) =>
    MediaRecorder.isTypeSupported(x)
  )?.[0];
  return {
    supportedAudioType: supportedMime,
    supportedAudioExtension: supportedMime
      ? `${supportedMime.substring(supportedMime.lastIndexOf("/") + 1)}`
      : "",
  };
};

export const setUserRole = (role: keyof typeof constants.userRoles) => {
  dataStore.auth.updateValue({role});
  localStore.auth.updateValue({role});
}

export const getMyRole = () => {
  return dataStore.auth.getValue()?.role || localStore.auth.getValue()?.role;
}

export const getAuthToken = () => {
  return dataStore.auth.getValue()?.token || localStore.auth.getValue()?.token;
}

export const appendLeadingZero = (num: number) => num < 10 ? `0${num}` : `${num}`;

const convertToSearchParamObject = (params: URLSearchParams): { [key: string]: any } => {
  const size = Array.from(params.keys()).length;
  if(!size) return {};
  return Array.from(params.entries()).reduce((acc, pair) => {
    const [param, value] = pair;
    const decodedValue = JSON.parse(decodeURIComponent(value));
    return { ...acc, [param]: decodedValue };
  }, {});
}

export const decodeParamsFromURI = (url: string) => {
  const queryString = url.split("?")[1];

  const dataParam = new URLSearchParams(queryString);
  return convertToSearchParamObject(dataParam);
};

export const shouldRevalidateWithSearchParams = (currentUrl: URL, nextUrl: URL, ignoreList: string[]) => {
  const currentUrlParams = convertToSearchParamObject(currentUrl.searchParams);
  return Array.from(nextUrl.searchParams.entries()).some(([key, value]) => {
    if(ignoreList.includes(key)) return false;
    const decodedValue = JSON.parse(decodeURIComponent(value));
    if(!currentUrlParams?.[key]) return !!decodedValue;
    return JSON.stringify(currentUrlParams?.[key]) !== JSON.stringify(decodedValue);
  })
}

export const getProgressFromAxios = (progress: AxiosProgressEvent, startTime: number = Date.now()) => {
  const loaded = progress.loaded ?? 0;
  const total = progress?.total ?? 0;
  const currentTime = Date.now();
  const timeDiff = (currentTime - startTime)/1000;
  const uploadedInKb = (loaded/timeDiff)/1000;
  const uploadedInMb = uploadedInKb >= 1000 ? uploadedInKb/1000 : null;
  const uploaded = uploadedInMb ?? uploadedInKb;
  const speed = (uploaded).toFixed(2);
  return(
    {
      progressPercentage: Math.round((progress.loaded/total)*100),
      speed: `${speed} ${uploadedInMb ? 'mb': 'kb'}/s`
    }
  )
};

export const removeUnderscore = (word:string) => {
  const test1 = word.replaceAll("_", " ");
  return test1.charAt(0).toUpperCase() + test1.slice(1);
};

export const ellipsisText = (text: string, cutoffLength: number) => {
  const displayFileName =
    text.length > cutoffLength ? "..." +text.slice(-cutoffLength): text;
  return displayFileName;
};

const pattern = /\/([^/]+)$/;
export const getFileNamefromURL = (url: string) => pattern.exec(url)![1];

export const getNameInitials = (name: string) => {
  const words = name.split(' ');
  return words.map((word)=> word.charAt(0)).join("");
}

export const getAverage = (ratings: any) => {
  const totalRatings = Object.values(ratings);
  const validRatings = totalRatings.filter(
    (r: any) => r.rating !== null && !isNaN(r.rating)
  );

  if (validRatings.length === 0) {
    return null;
  }
  const sum: any = validRatings.reduce((acc, r: any) => acc + r.rating, 0);
  return sum / validRatings.length;
};


export const formatDisplayValue = (score: number | null) =>
  score ? score.toFixed(2) + "%" : "Not Provided";

export const calculateAverageRating = (
  evaluation: any,
) => {
  const systemAverage = evaluation
    ? getAverage(evaluation)
    : null;
      return systemAverage;
  }