import { RouterProvider } from "react-router-dom";
import { router } from "./router";
import { GlobalSpinner } from "./components/GlobalSpinner";

function App() {
  return (
    <RouterProvider
      router={router}
      fallbackElement={<GlobalSpinner/>}
    />
  );
}

export default App;
