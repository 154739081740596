import { ILsBase } from "./lsBase";

export const createLS = <T, U = T>(ls: ILsBase, storeName: string) => {
    return {
        getValue: () => ls.getValue<T>(storeName),
        setValue: (value: T) => {
            ls.setValue(storeName, value as any);
        },
        updateValue: (value: T) => {
            const oldValues = ls.getValue<T>(storeName);
            if(typeof value === 'object'){
                ls.setValue(storeName, { ...oldValues, ...value } as any);
            } else {
                ls.setValue(storeName, value as any);
            }
        },
        deleteValue: (key?: Extract<keyof T, keyof U> | Array<Extract<keyof T, keyof U>>) => {
            const data = ls.getValue<T>(storeName);
            if(key && typeof data === 'object'){
                if(Array.isArray(key)){
                    const rest = Object.entries(data as object).reduce((res, [k,v]) => {
                        if(!key.some(x => x === k)) res[k] = v;
                        return res;
                    },{} as any)
                    ls.setValue(storeName, rest);
                } else {
                    if((data)?.[key]){
                        const { [key]:del, ...rest } = data;
                        ls.setValue(storeName, rest);
                    }
                }
            } else {
                ls.deleteValue(storeName)
            }
        },
        clearStore: () => ls.deleteValue(storeName)
    }
}