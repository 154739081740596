import { apiUrls } from "../constants/apiUrls";
import { AxiosAuthApi } from "../services/axiosAuthApi";

export interface ILoginApiResponse {
  data: {
    access_token: string;
    refresh_token: string;
    logout_url: string;
    user_role: string;
  }
}

export const loginApi = (url: string) => {
  return AxiosAuthApi.post<ILoginApiResponse>(apiUrls.login,{
      code: url,
      client: "screener"
    }
  );
}