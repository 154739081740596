import { screenerLogo } from "../../assets/icons";
import { joinClass } from "../../utils/helper";
import ss from "./styles.module.css";

type PropType = {
  width?: string;
  containerClassName?: string;
  className?: string;
  mainLogo?: boolean;
};

export const Logo: React.FC<PropType> = ({
  className,
  width = "116",
  mainLogo = true,
  containerClassName = "",
}) => {
  const logoContent = () => {
    if (mainLogo) {
      return (
        <img
          className={className}
          src={screenerLogo}
          alt="Screener Logo"
          style={{ width: `${width}px` }}
        />
      );
    }
    return (
      <div className={ss["logo-initial"]}>
        <span>S</span>
      </div>
    );
  };

  return (
    <div className={joinClass(ss.container, containerClassName)}>
      {logoContent()}
    </div>
  );
};
